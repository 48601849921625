import md5 from 'md5';
import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import {
    AuthModel,
    INewPassword,
    IRecoverPassword,
    UserAuthenticated,
} from '@models/auth/auth.models';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import Role from './roles.models';
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private apiUrl: string = environment.apiEndpoint;
    private http: HttpClient = inject(HttpClient);
    private router: Router = inject(Router);
    role: WritableSignal<string | null> = signal(null);
    loggedIn: WritableSignal<boolean> = signal(false);

    login(credentials: AuthModel): Observable<boolean> {
        return this.http
            .post<UserAuthenticated>(`${this.apiUrl}/Users/Auth`, credentials, {
                headers: new HttpHeaders({
                    TenantName: credentials.tenantName,
                }),
            })
            .pipe(
                map((response: UserAuthenticated) => {
                    const role = response.role;
                    const encryptedRole = md5(response.role);

                    this.storeUser({ ...response, role: encryptedRole });
                    this.role.set(role);
                    this.loggedIn.set(true);
                    return true;
                }),
                catchError(() => {
                    this.storeUser(null);
                    this.loggedIn.set(false);
                    return throwError(
                        () =>
                            'Email, senha ou parceiro inválidos, cheque os campos e tente novamente.'
                    );
                })
            );
    }

    logout(): void {
        this.storeUser(null);
        this.role.set(null);
        this.loggedIn.set(false);
        this.router.navigate(['login']);
    }

    getUser(): UserAuthenticated | null {
        const userFromStorage: string | null =
            sessionStorage.getItem('quironCurrentUser');

        if (!userFromStorage) return null;

        return JSON.parse(userFromStorage) as UserAuthenticated;
    }

    isAlive(): Observable<boolean> {
        return this.http.get<boolean>(`${this.apiUrl}/public/is-alive`).pipe(
            map((response: boolean) => {
                this.loggedIn.set(true);
                return response;
            })
        );
    }

    getRole(): Role {
        const user = this.getUser();

        if (user) {
            const encryptedRole = user.role;

            const roles = ['Owner', 'Admin', 'Reader'];

            for (const role of roles) {
                if (md5(role) === encryptedRole) {
                    this.role.set(role);
                    return role as Role;
                }
            }
        }

        this.logout();

        return null;
    }

    definePassword(data: INewPassword) {
        return this.http.post(`${this.apiUrl}/Users/DefinePassword`, data, {
            headers: new HttpHeaders({
                TenantName: data.tenantName,
            }),
        });
    }

    recoverPassword(data: IRecoverPassword) {
        return this.http.post(
            `${this.apiUrl}/Users/RecoveryPassword`,
            { email: data.email, tenantName: data.partner },
            {
                headers: new HttpHeaders({
                    TenantName: data.partner,
                }),
            }
        );
    }

    private storeUser(user: UserAuthenticated | null) {
        if (user == null) {
            sessionStorage.removeItem('quironCurrentUser');
            return false;
        }

        sessionStorage.setItem('quironCurrentUser', JSON.stringify(user));
        this.loggedIn.set(true);
        return true;
    }
}
